<template>
  <div class="topContainer">
    <div class="wrap-products-list">
      <img src="/img/bck/pesce.png" class="img-fluid sea " />
      <div class="container mb-5 containerProducts">
        <div class="row flex-lg-nowrap">
          <loading :active.sync="isLoading"></loading>
          <div class="container">
            <div
              v-if="!isLoading && items.length === 0"
            > {{$t('message.alert_no_records_found')}}
            </div>
            <b-row v-if="!isLoading && Object.keys(items).length >= 0">
              <b-col sm="12">
                <vueper-slides
                  :dragging-distance="70"
                  :slide-ratio="1 / 4"
                  :initSlide="parseInt(slide)"
                  :visible-slides="getVisibleSlides"
                  class="no-shadow"
                  fixed-height="550px"
                  vueperslides--animated
                  ref="productList"

                  :infinite="true"
                >
                  <vueper-slide
                    v-for="(item, index) in items"
                    :key=index
                    v-if="Object.keys(items).length >= 0 && !isLoading"
                  >
                    <template v-slot:content >

                      <div class="card mr-md-3 ml-md-3 bg-white shadow mt-1 rounded cardProduct ribbon ribbon-top">
                        <div class="card-body bg-white">
                          <div class="ribbon-target bg-danger" v-if="item.Trial === true">
                            {{$t('message.demo_ribbon')}}
                          </div>
                          <div class="productsListMinHeight">
                            <div class="p-lg-3">
                              <div class="text-center p-lg-3 mb-3" :class="getVpsCategory(item.Tag)">
                                <h5 class="text-center text-bg-color-theme my-1 productListCardTitle">{{item.Description}}</h5>
                              </div>
                            </div>
                            <div class="text-center bg-white cardProductPropertiesContainer">
                                <div class="cardPropertiesData">
                                  <div class="d-flex justify-content-center mb-2">
                                      <span class="font-md" >{{item.features.CORE.value}} vCPU</span>
                                  </div>
                                  <div class="d-flex justify-content-center mb-2">
                                    <span class="font-md" >{{item.features.RAM.value}} GB vRAM</span>
                                  </div>
                                  <div class="d-flex justify-content-center mb-2">
                                    <span class="font-md" >{{item.features.STONVME.value}} GB vSTO NVMe</span>
                                  </div>
                                  <div class="d-flex justify-content-center mb-2">
                                    <span class="font-md" >{{item.features.IPV4.value}} IPv4</span>
                                  </div>
                                  <div class="d-flex justify-content-center mb-2">
                                    <span class="font-md" >{{item.features.TRAFF.key}} {{item.features.TRAFF.value}}</span>
                                  </div>
                                  <div class="d-flex justify-content-center mb-2">
                                    <span class="font-md" >{{$t('message.bandwidth')}} {{item.features.BW.value}}</span>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div class="text-white text-center">
                            <div class="p-lg-2">
                              <router-link :to="{name:'productDetail',params: {productCode : item.Item_Code }}" class="nu">
                                <b-button block class="bg-theme-secondary" style="height: 98px !important">
                                  <div class="text-bg-color-theme">
                                    <span style="font-size: 22px">
                                      {{$t('message.start_from')}}<br/>{{item.Max_Unit_Price | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}
                                    </span>
                                    <small>{{$t('message.plus_vat')}}</small><br/>
                                    <small>{{$t('message.or_learn_how_to_save')}} {{item.Min_Unit_Price | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}} {{$t('message.plus_vat')}}</small>
                                  </div>
                                </b-button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </vueper-slide>
                </vueper-slides>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import Cart from "../components/cart/Cart";
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {VueperSlides, VueperSlide} from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  export default {
    name: 'products-list',
    props: {
      slide: [String,Number]
    },
    components: {
      Loading,
      VueperSlide,
      VueperSlides
    },
    data() {
      return {
        cart: null,
        items: [],
        itemsFilters: {},
        isLoading: false,
      };
    },
    methods: {
      getVpsCategory: function(category) {
        return category;
      }
    },
    computed: {
      getVisibleSlides() {
        if (window.innerWidth > 576) {
          return 3;
        } else {
          return 1;
        }
      },
    },
    mounted() {
      this.isLoading = true;
      Api.get(ApiResolver.get('PRODUCTS'))
        .then((response) => {
          this.items = response.data;
          this.isLoading = false;
        })
        .catch(function (error) {
          // handle error
          //console.log(error);
          this.isLoading = false;
        });
    },
  };
</script>

<style scoped>
  .nu {
    text-decoration: none;
  }
  .vueperslides__track-inner .card {
    padding: 0px 10px 20px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
  }
</style>

<style type="text/css">
  .productsListMinHeight {
    .min-height: 360px;
  }
  .Professional {
    background-color: #008BCE;
    color: #ffffff;
  }
  .Business {
    background-color: #00CFB4;
    color: #ffffff;
  }
  .Enterprise {
    background-color: #FFA400;
    color: #ffffff;
  }
  .cardProduct {
    height: 500px;
    width: 340px;
  }
  .cardProductPropertiesContainer {
    height: 240px;
    margin: 0 !important;
    vertical-align: middle;
  }
  .cardPropertiesData {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ribbon {
    position: relative;
  }
  .ribbon .ribbon-target {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    background-color: #3699FF;
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
    color: #FFFFFF;
  }
  .ribbon .ribbon-target > .ribbon-inner {
    z-index: -1;
    position: absolute;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .ribbon .ribbon-target:after {
    border-color: #004d9c;
  }
  .ribbon-ver .ribbon-target {
    padding: 5px 10px;
    min-width: 36px;
    min-height: 46px;
    text-align: center;
  }
  .ribbon.ribbon-top .ribbon-target {
    border-bottom-right-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem;
  }
  .ribbon.ribbon-bottom .ribbon-target {
    border-top-right-radius: 0.42rem;
    border-top-left-radius: 0.42rem;
  }
  .ribbon.ribbon-left .ribbon-target {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;
  }
  .ribbon.ribbon-right .ribbon-target {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem;
  }
  .ribbon.ribbon-clip.ribbon-left .ribbon-target {
    left: -10px;
  }
  .ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;
  }
  .ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:before, .ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent !important;
    bottom: -10px;
  }
  .ribbon.ribbon-clip.ribbon-left .ribbon-target .ribbon-inner:before {
    border-width: 0 10px 10px 0;
    border-right-color: #181C32 !important;
    left: 0;
  }
  .ribbon.ribbon-clip.ribbon-right .ribbon-target {
    right: -10px;
  }
  .ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem;
  }
  .ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:before, .ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent !important;
    bottom: -10px;
  }
  .ribbon.ribbon-clip.ribbon-right .ribbon-target .ribbon-inner:before {
    border-width: 0 0 10px 10px;
    border-left-color: #181C32 !important;
    right: 0;
  }

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px) {

    .topContainer {
      margin-top: 25px;
    }

    /* product list overrides */
    img.sea {
      display: none !important;
    }
    .wrap-products-list {
      background: none !important;
    }
    .containerProducts {
      margin-bottom: 0px !important;
    }
    .cardProduct {
      margin-top: 5px !important;
      box-shadow: none !important;
      border: 1px solid #cccccc !important;
      height: 515px;
      position: absolute;
      margin-left: 50%;
      margin-right: auto;
      text-align: left;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .productListCardTitle {
      padding: 14px !important;
      margin-top: 20px !important;

    }
    .productsListMinHeight {
      .min-height: 320px !important;
    }
    .vueperslides__bullets {
      bottom: -35px !important;
    }
    .vueperslides__bullet--active {
      color: #000 !important;
    }
    .cardProductPropertiesContainer {
      height: 280px !important;
    }
    .cardPropertiesData {
      margin-top: 45px;
      margin-bottom: 15px;
    }
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px) {
    .cardProduct {
      margin-top: 0 !important;
      box-shadow: none !important;
      border: 1px solid #cccccc !important;
      height: 460px;
      width: 210px !important;
      margin-left: 25%;
      margin-right: 25%;
      text-align: left;
    }
    .productListCardTitle {
      font-size: 14px !important;
      padding: 20px !important;
      margin-top: 15px !important;
    }
    /* product list overrides */
    img.sea {
      display: none !important;
    }
    .wrap-products-list {
      background: none !important;
    }
    .cardProductPropertiesContainer {
      height: 250px !important;
    }
  }

</style>
